<div
  class="warning-container lg:w-[450px] md:w-[450px] sm:w-[450px] w-[100%] h-auto my-[12px]">
  <div class="w-[100%] h-[100%] flex justify-center">
    <mat-icon>warning</mat-icon>
  </div>
  <p
    class="lg:text-[20px] md:text-[20px] text-[18px] font-bold p-6 text-[#777676] text-center">
    {{ warningMessage | translate | async }}
  </p>
  <div class="flex justify-center gap-3 mb-2">
    <button mat-raised-button (click)="close()">
      {{ 'story.cancel' | translate | async }}
    </button>
    <div class="confirm-btn">
      <button mat-raised-button (click)="approveProcess()">Ok</button>
    </div>
  </div>
</div>
